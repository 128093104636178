class App {
  toggleMenus = document.querySelectorAll('.toggle-menu') as NodeListOf<HTMLElement>;
  menu = (document.querySelector('#responsive-menu') as HTMLElement).classList;

	constructor() {
	}

  start() {
    this.navigation()
		this.mediaVideo()
    this.initForm()
  }

	navigation() {
		this.toggleMenus.forEach(toggler => {
			toggler.addEventListener('click', () => {
				const timeout = this.menu.contains('open') ? 500 : 0
				setTimeout(() => this.menu.toggle('hidden'), timeout);
				this.menu.toggle('open')
			})
		})
	}

	mediaVideo() {
    const videos = document.querySelectorAll('.media-video') as NodeListOf<HTMLVideoElement>
		videos.forEach((v: HTMLVideoElement) => {
			try {
				v.muted = true
				v.load()
				v.play()
			} catch(e) {
				alert('Cannot play' + v.src + ': ' + e)
				v.controls = true
			}
		})
	}
  initForm(){
    const form = document.querySelector('form')
    if(form != null){
      form.addEventListener('submit',(e)=>{
        document.getElementById('loader').classList.remove('hide','hideLoader')
      })
    }

  }
}

window.addEventListener('load', () => {
	const app = new App()
	app.start()
	const main = document.querySelector('main') as HTMLElement

  var route = ''
  if(main.getAttribute('child') === 'true' || window.location.pathname.includes('/en')){
    route = `../assets/js/${main.id}.min.js`
  }else{
    route = `assets/js/${main.id}.min.js`
  }


	const script = document.createElement('script')
	script.src = route
	script.async = true
	script.type = 'module'
	document.body.appendChild(script)

	script.onload = (s) => {

	}

	script.onerror = () => {
		console.error(`Error loading ${route}`)
	}
})
